import React, { useEffect } from 'react';
import { Box, Grid, Typography, Container } from '@mui/material';
import DataAlbums from './albums.json';
import  Nu from '../../imgs/adlbum/NU_cover.jpg';
import  Hjerte from '../../imgs/adlbum/Hjerte_cover.jpg';
import  Hjem from '../../imgs/adlbum/Hjem_cover.jpg';
import Her from '../../imgs/adlbum/her_nu_3000x3000.jpg';
import Tid from '../../imgs/adlbum/Vores tid_cover.jpg';

function Albums() {
    const [isMobile, setIsMobile] = React.useState(false);

    //check if the screen is mobile
    useEffect(() => {
        if (window.innerWidth <= 960) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const albums = DataAlbums;
    //add the images to the json file
    for (let i = 0; i < albums.length; i++) {
        switch (albums[i].img) {
            case 'Nu':
                albums[i].img = Nu;
                break;
            case 'Hjerte':
                albums[i].img = Hjerte;
                break;
            case 'Hjem':
                albums[i].img = Hjem;
                break;
            case 'Her':
                albums[i].img = Her;
                break;
            case 'Tid':
                albums[i].img = Tid;
                break;
            default:
                break;
        }
    }

    if (isMobile) {
        return (
            <Box
                sx={{
                    bgcolor: '#0e151e',
                    paddingY: 4,
                    minHeight: '100vh',
                    color: 'white'
                }}
            >
                <Container>
                    <Typography variant="h4" component="h1" sx={{ mb: 4, textAlign: 'center' }}>
                        Albums
                    </Typography>
                    <Box sx={{ display:'flex', flexDirection:'column', alignItems: 'center' }}>
                    {albums.map((album, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: "fit-content",
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                px: 2,
                                mb: 4
                            }}
                        >
                            <Box
                                component="img"
                                src={album.img}
                                alt={album.name}
                                sx={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 1,
                                    mb: 2,
                                    display: 'block'
                                }}
                            />
                            <Typography variant="body1" sx={{ textAlign: 'center', mx: 2 }}>
                                {album.description}
                            </Typography>
                        </Box>
                    ))}
                    </Box>
                </Container>
            </Box>
        );
    } else {
        return (
            <Box
                sx={{
                    bgcolor: '#0e151e',
                    paddingY: 4,
                    minHeight: '80vh',
                    color: 'white',
                }}>
            <Container maxWidth="ml" sx={{ 
                width: '80%',

            }}>
                    <Typography variant="h4" component="h1" sx={{ mb: 4, textAlign: 'center' }}>
                        Albums
                    </Typography>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={6} >
                            <Box>
                                {albums.slice(0, 3).map((album, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            mb: 4,
                                            padding: 2,
                                            borderRadius: 1
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            src={album.img}
                                            alt={album.name}
                                            onClick={() => window.open(album.link)}
                                    // open a new tap the the like in a new tap album.link
                                            sx={{
                                                //add the cursor pointer
                                                cursor: 'pointer',
                                                width: 150,
                                                backgroundColor: 'white',
                                                height: 150,
                                                borderRadius: 1,
                                                display: 'block'
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ textAlign: 'center', mx: 2 }}>
                                            {album.description}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height:"95%" }}>
                                {albums.slice(3).map((album, index) => (
                                    <Box
                                        key={index}

                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            mb: 4,
                                            padding: 2,
                                            borderRadius: 1
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            onClick={() => window.open(album.link)}
                                            src={album.img}
                                            alt={album.name}
                                            sx={{
                                                cursor: 'pointer',
                                                backgroundColor: 'white',
                                                width: 150,
                                                height: 150,
                                                borderRadius: 1,
                                                display: 'block'
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ textAlign: 'center', mx: 2 }}>
                                            {album.description}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        );
    }
}

export default Albums;

