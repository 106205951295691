import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import ScIntro from './sc_intro';

function ScIndex() {
    return (
        <React.Fragment>
        <ScIntro />
        </React.Fragment>
    );
}

export default ScIndex;


