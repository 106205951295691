import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, CssBaseline, Button} from '@mui/material';

//import image from public/images/band1.jpg;
import PhFrinds from '../../imgs/Halberg-Friends.jpg';
import Powertrio from '../../imgs/POWERTRIO.jpg';
import Santacruz from '../../imgs/SANTACRUZ.jpg';

import PhfrindsImg1 from '../../imgs/frontImgs/phAndF1.jpg';
import PhfrindsImg2 from '../../imgs/frontImgs/phAndF2.jpg';
import PhfrindsImg3 from '../../imgs/frontImgs/phAndF3.jpg';
import ScImg1 from '../../imgs/frontImgs/Santa_Cruz1.jpg';
import PowerImg from '../../imgs/frontImgs/powertrio1.jpg';

//background images 
//
const backgroundImages = [
    { band: 'Santa Cruz', images: [ScImg1] },
    { band: 'Powertrio', images: [PowerImg] },
    { band: 'Halberg & Friends', images: [PhfrindsImg1, PhfrindsImg2, PhfrindsImg3] }
];

const cards = [
    { id: 1, description: 'Santa Cruz', img: Santacruz, color: '#f15e48', link: '/santacruz' },
    { id: 2, description: 'Powertrio',  img: Powertrio , color: '#20549d', link: '/powertrio' },
    { id: 3, description: 'Halberg & Friends', img: PhFrinds, color: '#46d0b6', link: '/HalbergFriends' },
];

function StackedBandSlider() {
    const [currentIndex, setCurrentIndex] = useState(1); // Start at card 2
  const [lastClickedTime, setLastClickedTime] = useState(0);

  const handleCardClick = (index) => {  
      if (index !== currentIndex) {
          setCurrentIndex(index);
          setLastClickedTime(Date.now());
      }
  };

  useEffect(() => {
      const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
          setLastClickedTime(Date.now());
      }, 7000);
      return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
      const now = Date.now();
      const timeSinceLastClick = now - lastClickedTime;

      if (timeSinceLastClick >= 2000) { // Wait for 2 seconds before changing the background
          handleBackgroundimage();
      } else {
          const timeout = setTimeout(() => {
              handleBackgroundimage();
          }, 2000 - timeSinceLastClick);

          return () => clearTimeout(timeout);
      }
  }, [currentIndex]);

  function handleBackgroundimage() {
      const currentBackgroundImageElement = document.querySelector('.current-background-image');
      const newBackgroundImageElement = document.querySelector('.new-background-image');
      
      // Set the new background image
      const currentBackground = backgroundImages.find(bg => bg.band === cards[(currentIndex + 1) % cards.length].description);
      newBackgroundImageElement.style.backgroundImage = `url(${currentBackground.images[Math.floor(Math.random() * currentBackground.images.length)]})`;

      // Start fade-out and fade-in effect
      currentBackgroundImageElement.style.opacity = 0;
      newBackgroundImageElement.style.opacity = 1;

      // Swap the classes after the transition
      setTimeout(() => {
          currentBackgroundImageElement.classList.remove('current-background-image');
          currentBackgroundImageElement.classList.add('new-background-image');

          newBackgroundImageElement.classList.remove('new-background-image');
          newBackgroundImageElement.classList.add('current-background-image');
      }, 2000); // Duration of fade-out and fade-in effect
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: '#0e151e', // Adjusted background for better visibility
        }}
      >
        <Box 
          className="background-image current-background-image"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            filter: 'brightness(0.4)',
            backgroundPosition: 'center',
            top: 0,
            left: 0,
            transition: 'opacity 1s ease-in-out', // Transition for fading effect
            opacity: 1,
          }}
        />
        <Box 
          className="background-image new-background-image"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            filter: 'brightness(0.4)',
            backgroundPosition: 'center',
            top: 0,
            left: 0,
            transition: 'opacity 1s ease-in-out', // Transition for fading effect
            opacity: 0,
          }}
        />
          <Typography 
      className="header-text"
      variant="h3"
       sx={{  color: 'white',
              zIndex: 2,
          }}>
              Poul Halberg
          </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80%',
            width: '80%',
            position: 'relative',
          }}
        >
      <Typography variant="h3" sx={{ position: 'absolute', top: '1rem', color: 'white' }}>
      </Typography>
       {cards.map((card, index) => {
            const relativeIndex = (index - currentIndex + cards.length) % cards.length;
            return (
              <Box
                key={card.id}
                sx={{
                    position: 'absolute',
                  width: 'calc(2.5 * 20rem / 2.8)', // Maintain 2.5:3.5 ratio
                    height: '20rem', // Base height
                  transition: 'transform 0.5s, opacity 0.5s, z-index 0.5s',
                  transform: `translateX(${(relativeIndex - 1) * 85}%)
                    scale(${relativeIndex === 1 ? 1 : 0.6})`,
                  zIndex: relativeIndex === 1 ? 1 : 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleCardClick(index - 1)}
              >
                <Card
                  sx={{
                    width: '100%',
                    height: '100%',
                          display: 'flex',
                    borderRadius: '1rem',
                    justifyContent: 'center',
                    backgroundImage: `url(${card.img})`,
                    backgroundSize: 'cover', 
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    
                  }}
                >
                </Card>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '-3rem', // Position below the card
                      width: '100%',
                      textAlign: 'center',
                      color: 'white',
                    }}
                >
                  </Box>
                </Box>

            );
          })}
        <Button 
          variant="outlined" 
          href={cards[(currentIndex + 1) % cards.length].link}
          sx={{ 
              mt: 2, 
              '&:hover': {
                  border: `2px solid ${cards[(currentIndex + 1) % cards.length].color}`
              },
            color: 'white', 
            position: 'absolute',
            bottom: '0',
          zIndex: 1,
            backgroundColor: 'transparent',
            border: `2px solid ${cards[(currentIndex + 1) % cards.length].color}`,
            fontFamily: 'Chango', 
            fontSize: '1.5rem' 
          }}>
          {cards[(currentIndex + 1) % cards.length].description}
        </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default StackedBandSlider;


