import { Box, Typography, Grid, Card, CardContent,  Container, Button, Tooltip } from '@mui/material';


function DeTreBandsBorder(){
    return(
<Box>
<Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  flexDirection={{ xs: 'row', sm: 'row' }}
  mt={0}
  mb={2}
>
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    mx={{ xs: 3, sm: 1 }}
    mb={{ xs: 3, sm: 0 }}
  >
    <Box
      width="2rem"
      height="2rem"
      sx={{ backgroundColor: 'transparent', color: 'white', padding: 2, borderRadius: 2, border: '2px solid #f15e48' }}
    ></Box>
    <Typography variant="body2" mt={1}>
      Santa Cruz
    </Typography>
  </Box>
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    mx={{ xs: 3, sm: 1 }}
    mb={{ xs: 5, sm: 0 }}
  >
    <Box
      width="2rem"
      height="2rem"
      sx={{ backgroundColor: 'transparent', color: 'white', padding: 2, borderRadius: 2, border: '2px solid #20549d' }}
    ></Box>
    <Typography variant="body2" mt={1}>
      Powertrio
    </Typography>
  </Box>
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    mx={{ xs: 3, sm: 1 }}
    mb={{ xs: 3, sm: 0 }}
  >
    <Box
      width="2rem"
      height="2rem"
      sx={{ backgroundColor: 'transparent', color: 'white', padding: 2, borderRadius: 2, border: '2px solid #46d0b6' }}
    ></Box>
    <Typography variant="body2" mt={1}>
      HP & Friends
    </Typography>
  </Box>
</Box>
</Box>
    )
}

export default DeTreBandsBorder;
