import React from 'react';
import FirstSection from './fristSecktions';
import StackedBandSlider from './bands';
import Kalender from './kalender.js';
import FilmMusik from './filmMusik.js';



function MainSection() {
  return (
    <React.Fragment>
      <StackedBandSlider />
      <Kalender />
      <FilmMusik />
    </React.Fragment>
  );
}

export default MainSection;
