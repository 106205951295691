import React from 'react';
//add a json file with the timeline data
import jsonDate from './timeline.json';
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';


const TimelineItem = ({ year, description }) => (
    <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', marginBottom: '1rem' }}>
    </Box>
    <Container maxWidth="md">
      <Card sx={{ marginY: '1rem', backgroundColor: '#1e2633' }}>
        <CardContent>
          <Typography variant="h6" sx={{ color: 'white', width: '200px', textAlign: 'left'
          }}>{year}</Typography>
          <Typography variant="body1" sx={{ color: 'white'
          }}>{description}</Typography>
        </CardContent>
      </Card>
    </Container>
    </Container>
);

function Cv() {
    const timelineData = jsonDate;
  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      backgroundColor="#0e151e"
      sx={{ overflowY: 'scroll', paddingY: '2rem' }}
    >
      <Typography variant="h3" sx={{ color: 'white', marginBottom: '2rem' }}>
        Om Poul Halberg
      </Typography>
      <Typography variant="h5" sx={{ color: 'white', marginBottom: '2rem' }}>
        TimeLine:
      </Typography>
        {timelineData.map((item, index) => (
          <TimelineItem key={index} year={item.year} description={item.description} />
        ))}
    </Box>
  );
}

export default Cv;

