import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent,  Container, Button, Tooltip } from '@mui/material';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, parse, subMonths, addMonths, differenceInDays } from 'date-fns';
import { da } from 'date-fns/locale';
import axios from 'axios';
import DeTreBandsBorder from './DeTreBandsBorder';

    //  { id: 1, description: 'Santa Cruz', img: Santacruz, color: '#f15e48' },
    //  { id: 2, description: 'Powertrio',  img: Powertrio , color: '#20549d' },
    //  { id: 3, description: 'Halberg & Friends', img: PhFrinds, color: '#46d0b6' },
const Kalender = () => {
    //fetch events from the API
    const [events, setEvents] = useState([]);

    useEffect(() => {
        axios.get('https://www.api.kilofon.dk/api/getAll')
            .then(res => {
                //add a color to each event if its Santa Cruz, Powertrio or Halberg & Friends
                setEvents(res.data.koncert.map(event => {
                    if (event.band === 'Santa Cruz') {
                        event.color = '#f15e48';
                    } else if (event.band === 'Powertrio') {
                        event.color = '#20549d';
                    } else if (event.band === 'Halberg & Friends') {
                        event.color = '#46d0b6';
                    }
                    return event;
                }));
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

  const [currentDate, setCurrentDate] = useState(new Date());
    const [calendar, setCalendar] = useState([]);
    const [currentBox, setCurrentBox] = useState(null);

    const selectedDate = currentBox !== null ? calendar[currentBox] : null;
    const selectedEvent = selectedDate ? events.find(event => isSameDay(selectedDate, parse(event.date, 'dd-MM-yyyy', new Date()))) : events.find(event => isSameDay(new Date(), parse(event.date, 'dd-MM-yyyy', new Date())));




  useEffect(() => {
    const startDate = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 }); // weekStartsOn: 1 sets the week to start on Monday
    const endDate = endOfWeek(endOfMonth(currentDate), { weekStartsOn: 1 });
    const days = [];

    let day = startDate;
    while (day <= endDate) {
      days.push(day);
      day = addDays(day, 1);
    }

    // Ensure the calendar always has 6 rows
    const totalDays = differenceInDays(endDate, startDate) + 1;
    const requiredDays = 6 * 7;
    if (totalDays < requiredDays) {
      let extraDays = requiredDays - totalDays;
      while (extraDays > 0) {
        days.push(day);
        day = addDays(day, 1);
        extraDays--;
      }
    }

  const todayIndex = days.findIndex(d => isSameDay(d, new Date()));
    setCurrentBox(todayIndex !== -1 ? todayIndex : null);


    setCalendar(days);
  }, [currentDate]);

    const setCurrnetBox = (index) => {
        setCurrentBox(index);
        const date = calendar[index];
        const event = events.find(event => isSameDay(date, parse(event.date, 'dd-MM-yyyy', new Date())));
        if (event) {
            return event;
        }
        return null;
    }

    const setSelectedEvent = (index) => {
        if (selectedEvent !== null) {
            setCurrentBox(index);
        }
    }


  const renderHeader = () => {
    const monthFormat = 'MMMM';
    const yearFormat = 'yyyy';
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} width="100%">
        <Button variant="contained" fontSize="small" 
        onClick={() => setCurrentDate(subMonths(currentDate, 1))}>
        Forrige
        </Button>
        <Box>
            <Typography variant="h5">{format(currentDate, monthFormat, { locale: da })}</Typography>
        </Box>
        <Button variant="contained" fontSize="small"
        onClick={() => setCurrentDate(addMonths(currentDate, 1))}>
        Næste
        </Button>
      </Box>
    );
  };

  const renderDays = () => {
    const daysOfWeek = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'];
    return (
      <Grid container>
        {daysOfWeek.map(day => (
          <Grid item xs={12/7} key={day}>
            <Typography align="center" variant="subtitle1">
              {day}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderCells = () => {
    return (
        <Grid container spacing={1} marginTop={1}>
        {calendar.map((day, index) => (
          <Grid item xs={12 / 7} key={index}>
            <Box
            elevation={0}
            textAlign="center"
            display="flex"
            borderRadius="16%"
            alignItems="center"
            justifyContent="center"
            backgroundColor= {currentBox === index ? 'rgba(255, 255, 255, 0.2)' : 'transparent'}
            onClick={() => setCurrnetBox(index)}
            sx={{
                height: { xs: '2.5rem', md: '3rem'},
                width: { xs: '2.5rem', md: '3rem'},
                "&:hover": {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    cursor: 'pointer',
                },
                    //is the day one of the events, then add a border
                border: events.some(event => isSameDay(day, parse(event.date, 'dd-MM-yyyy',
                    new Date()))) ? '2px solid ' + events.find(event => isSameDay(day, parse(event.date, 'dd-MM-yyyy',
                        new Date()))).color : '1px solid white',
                  position: 'relative',
                }}
              >
                <Typography align="center" variant="body2">
                  {format(day, 'd')}
                </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };
    return (
    <Box
      sx={{
        backgroundColor: '#1d2a3d',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '80vh',
        color: 'white',
        padding: 2,
      }}
    >
      <Container maxWidth="lg" sx={{ flexGrow: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Container
              maxWidth="xs"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: '#1d2a3d',
                padding: 2,
              }}
            >
              <Typography variant="h3" gutterBottom>
                Kalender
              </Typography>
              <Typography variant="h4" gutterBottom>
                {format(currentDate, 'yyyy', { locale: da })}
              </Typography>
            {DeTreBandsBorder()}
              {renderHeader()}
              {renderDays()}
              {renderCells()}
            </Container>
          </Grid>
        <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
            <Container maxWidth="sm" sx={{ minHeight: '20vh' }}>
        <Typography variant="h4" component="h1" sx={{ color: 'white' }} textAlign="center" mb={2}>
            {selectedEvent ? selectedEvent.band : ''}
            </Typography>
              {currentBox !== null && selectedDate && (
                selectedEvent ? (
                  <Card sx={{ mb: 2, bgcolor: '#2b3d5b', color: 'white' }}>
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6" component="h2">
                              {format(selectedDate, 'd MMMM yyyy', { locale: da })}
                            </Typography>
                          </Box>
                        <Typography variant="h6" component="p">
                            {selectedEvent.description.replace('køb billet', '').trim()}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end">
                          <Button variant="contained" color="success">
                            køb billet
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ) : (
                  <Card sx={{ mb: 2, bgcolor: '#2b3d5b', color: 'white' }}>
                    <CardContent>
                      <Typography variant="h6" component="p">
                        Ingen koncerter { isSameDay(selectedDate, new Date()) ? 'i dag' : 'denne dag' }
                      </Typography>
                    </CardContent>
                  </Card>
                )
              )}
            </Container>
        </Grid>
          </Grid>
      </Container>
    </Box>
  );
};

export default Kalender;

