import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Container, Button, Grid, Alert } from '@mui/material';
import axios from 'axios';


const KommendeKoncerter = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [events, setEvents] = useState([]);
    const itemsPerPage = 4;


    useEffect(() => {
        axios.get('https://www.api.kilofon.dk/api/getkoncert')
            .then(res => {
                res.data.koncert.map(event => {
                    if (event.band === 'Halberg & Friends') {
                        setEvents(prevEvents => [...prevEvents, event]);
                    }
                }
            )
        }
            )
    }, []); 

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    const options = { weekday: 'long', day: 'numeric', month: 'numeric' };
    return date.toLocaleDateString('da-DK', options);
  };

  const totalPages = Math.ceil(events.length / itemsPerPage);

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentEvents = events.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Box
      sx={{
        bgcolor: '#1d2a3d',
        display: 'flex',
        paddingY: 4,
        minHeight: '50vh',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" component="h1" sx={{ color: 'white', mb: 2 }} >
        Kommende koncerter
      </Typography>
      <Container maxWidth="sm">
        {currentEvents.map((event, index) => (
          <Card key={index} sx={{ mb: 2, bgcolor: '#2b3d5b', color: 'white' }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8} md={8}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" component="h2">
                      {event.band} - {formatDate(event.date)}
                    </Typography>
                  </Box>
                  <Typography variant="h6" component="p">
                    {event.description.replace('køb billet', '').trim()}
                  </Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  {event.description.includes('køb billet') && (
                    <Button variant="contained" color="success">
                      køb billet
                    </Button>
                  )}
                {event.status === 'udsolgt' && (
                    <Alert severity="warning" sx={{ width: '100%' }}>
                        Udsolgt
                    </Alert>
                    )}
            {event.status === 'aflyst' && (
                <Alert severity="error" sx={{ width: '100%' }}>
                    Aflyst
                </Alert>)}
            {event.status === 'fri entre' && (
                <Alert severity="success" sx={{ width: '100%', textAlign: 'center' }}>
                    Fri entré
                </Alert>)}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="primary" onClick={handlePrev} disabled={currentPage === 1}>
            Forrige
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext} disabled={currentPage === totalPages}>
            Næste
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default KommendeKoncerter;
