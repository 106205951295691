import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import Sant from '../../imgs/SANTACRUZ.jpg';


function Sc_intro() {
return (
        <Container minHeight="100vh" display="flex" flexDirection="column" alignItems="center" backgroundColor="#0e151e" sx={{ marginBottom: 5 }}>
        <Typography variant="h3" gutterBottom textAlign="left" my={6} color="white">
                Santa Cruz
        </Typography>

        <Grid container spacing={1} >
        <Grid item xs={12} sm={6}>
                    <Box
                        width="100%"
                        height={{ xs: '40vh', md: '75vh' }} // Adjust height for mobile and desktop
                        borderRadius="1rem"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            backgroundImage: `url(${Sant})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}>
                        <Box
                            backgroundColor="rgba(0, 0, 0, 0.2)" 
                            width="100%"
                            borderRadius="1rem"
                            height="100%" />
                    </Box>
            </Grid>
        <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center" color="white">
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{ marginBottom: { xs: 0, md: 5 } }} 
                        padding={{ xs: 2, md: 4 }} // Adjust padding for mobile and desktop
                    >
                        <Typography variant="h4" gutterBottom textAlign="center" sx={{ marginBottom: 4 }}>
                            Santa Cruz
                        </Typography>
                        <Typography variant="body1" gutterBottom fontSize="1.2rem" textAlign="center" >
                            En farverig rejse gennem 40 års sangskrivning.
                        </Typography>
                        <Typography variant="body1" gutterBottom fontSize="1.2rem" textAlign="center">
                            Sange som ”Magi i Luften”, ”Efterår”, ”Elskes af dig”, ”Spiller et spil”,
                        </Typography>
                        <Typography variant="body1" gutterBottom fontSize="1.2rem" textAlign="center">
                            ”Mandagsstævnemøde” "Brændende Læber" og ”Transit”
                        </Typography>
                        <Typography variant="body1" gutterBottom fontSize="1.2rem" textAlign="center">
                            bliver leveret med intensitet, nerve og masser af spilleglæde...
                        </Typography>
                    </Box>
        </Grid>
        </Grid>
        </Container>
    );
}

export default Sc_intro;
