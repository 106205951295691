import './App.css';
import MainSection from './pages/homepages/main';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useLocation } from 'react-router-dom';
import {Box} from '@mui/material';
import DrawerNav from './pages/drawerNav';
import Kalender from './pages/homepages/kalender';
import Koncerter from './pages/ph_page/koncerter';
import PhFrinds from './pages/ph_page/ph_frinds.js';
import SantaCruz from './pages/sc_page/index_sc.js';
import axios from 'axios';
import Kontakt from './pages/homepages/kontakt';
import Pw_main from './pages/pw_page/Pw_main.js';
import Cv from './pages/homepages/cv.js';


const LogPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const logPageView = async () => {
      await fetch('https://www.api.kilofon.dk/api/getPage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: location.pathname }),
      });
    };

    logPageView();
  }, [location]);

  return null;
};


function App() {
  return (
      <Router>
      <DrawerNav />
      <LogPageView />
      <Box component="main">
      <Routes>
      <Route path="/" element={<MainSection />} />
      <Route path="/koncerter" element={<Koncerter />} />
      <Route path="/halbergfriends" element={<PhFrinds />} />
      <Route path="/santacruz" element={<SantaCruz />} />
      <Route path="/powertrio" element={<Pw_main />} />
      <Route path="/kontakt" element={<Kontakt />} />
      <Route path="/om" element={<Cv />} />
          <Route
            path="*"
            element={
              <div>
                <h1 style={{ textAlign: 'center', color: 'white'
                }}>404</h1>
              </div>
            }
          />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;

