import React from 'react';
import KommendeKoncerter from './koncerter';
import Ph_intro from './intro';
import Albums from './albums.js';


//#0e151e
function PhFrinds() {
    return (
        <React.Fragment>
        <Ph_intro />
        <KommendeKoncerter />
        <Albums />
        </React.Fragment>
        
    );
}

export default PhFrinds;

