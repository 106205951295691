import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, Button, IconButton, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Popper, Paper, ClickAwayListener, MenuList, Grow } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const menuItems = [
  { text: 'Home', path: '/' },
    { 
        text: "band's", 
        subMenu: [ 
            { text: 'Santa Cruz', path: '/SantaCruz' },
            { text: 'Powertrio', path: '/Powertrio' },
            { text: 'Halberg & Friends', path: '/HalbergFriends' }
        ]
    },
  { 
    text: 'Koncerter', 
    subMenu: [ 
      { text: 'Santa Cruz', path: '/SantaCruz' },
      { text: 'Powertrio', path: '/Powertrio' },
      { text: 'Halberg & Friends', path: '/HalbergFriends' }
    ]
  },
  { text: 'Kontakt', path: '/Kontakt' },
  { text: 'Om', path: '/Om' },
];

const MyAppBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState([]);

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const handleMenuClick = (event, items) => {
    setAnchorEl(event.currentTarget);
    setSubMenuItems(items);
    setOpenSubMenu(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubMenuItems([]);
    setOpenSubMenu(false);
  };

  return (
    <React.Fragment>
      <AppBar color="transparent" style={{ color: 'white', boxShadow: 'none' }}>
        <Toolbar style={{ color: 'white' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* App Title */}
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {menuItems.map((item) => (
              item.subMenu ? (
                <div key={item.text} style={{ display: 'inline-block' }}>
                  <Button
                    color="inherit"
                    onClick={(event) => handleMenuClick(event, item.subMenu)}
                  >
                    {item.text}
                  </Button>
                  <Popper
                    open={openSubMenu}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem={openSubMenu}>
                              {subMenuItems.map((subItem) => (
                                <MenuItem
                                  key={subItem.text}
                                  component={Link}
                                  to={subItem.path}
                                  onClick={handleMenuClose}
                                >
                                  {subItem.text}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              ) : (
                <Button key={item.text} color="inherit" component={Link} to={item.path}>
                  {item.text}
                </Button>
              )
            ))}
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ ml: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <div key={item.text}>
                <ListItem button component={Link} to={item.path}>
                  <ListItemText primary={item.text} />
                </ListItem>
                {item.subMenu && item.subMenu.map((subItem) => (
                  <ListItem button key={subItem.text} component={Link} to={subItem.path} sx={{ pl: 4 }}>
                    <ListItemText primary={subItem.text} />
                  </ListItem>
                ))}
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default MyAppBar;

