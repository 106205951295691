import React from 'react';
import { Box, Grid, Card, Typography, CssBaseline, Link, CardContent } from '@mui/material';

function Kontakt() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
          color: 'white',
          backgroundColor: '#1d2a3d',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
      >

      <Typography variant="h4" component="h1" sx={{ color: 'white',
           textAlign: 'center', marginTop: '2rem', marginBottom: '2rem'
      }} >
      Kontakt de enkelte bands:
      </Typography>


        <Grid container spacing={2} justifyContent="center" width="100%">
          <Grid item xs={12} md={4} height="100%">
            <Box
              sx={{
                padding: '4rem',
                      zIndex: '3',
              }}
            >
              <Card
                sx={{
                  backgroundColor: '#2e3b4e',
                     py: 3,
                  color: 'white',
                  borderRadius: '1rem',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(255, 255, 255, 0.3)',
                  },
                }}
              >
                  <CardContent sx={{ textAlign: 'center', color: 'white' }}>
                  <Typography variant="h5" component="h2">
                    Halberg & Friends
                  </Typography>
                  <Typography variant="h6">
                    Andreas Holmsgaard
                  </Typography>
                    <Typography variant="h6">
                     ah@pdh.dk
                  </Typography>
                  <Typography variant="h6">
                    +45 33 44 45 05
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                padding: '4rem',
              }}
            >
              <Card
                sx={{
                  backgroundColor: '#2e3b4e',
                  color: 'white',
                     py: 3,
                  borderRadius: '1rem',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(255, 255, 255, 0.3)',
                  },
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" component="h2">
                    Powertrio
                  </Typography>
                  <Typography variant="h6">
                    Jesper Vander Nielsen
                  </Typography>
                  <Typography variant="h6">
                    jesper@fmk.nu
                  </Typography>
                  <Typography variant="h6">
                    51 37 23 14
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                padding: '4rem',
              }}
            >
              <Card
                sx={{
                  backgroundColor: '#2e3b4e',
                    py: 5,
                  color: 'white',
                  borderRadius: '1rem',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(255, 255, 255, 0.3)',
                  },
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" component="h2">
                    Santa Cruz
                  </Typography>
              <Typography variant="h6">
              Link til Facebook:
              </Typography>
                  <Link
                    href="https://www.facebook.com/pages/Santa-Cruz-fusion-band/369053716441457?scroll_to_composer=1&ref=stale_email"
                    target="_blank"
                    rel="noopener"
                    variant="h6"
                  >
                    Santa Cruz 
                  </Link>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Kontakt;

