import React from 'react';
import Pw_intro from './intro';
import Pw_koncerter from './PowerKoncerter';

function Pw_main() {
    return (
        <React.Fragment>
            <Pw_intro />
            <Pw_koncerter />
        </React.Fragment>
    );
}

export default Pw_main;
