import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Button,  CardMedia, Typography, Grid } from '@mui/material';
import GooseImg from "../../imgs/FilmImg/GooseBoy.jpg";
import JegVilHa from "../../imgs/FilmImg/derVarEngang.jpg";
import FarMor from "../../imgs/FilmImg/farmorImg.jpg";
import JuileStjerner from "../../imgs/FilmImg/julestjerner.jpg";
import { styled } from '@mui/system';

const listoOfFilms = [
    {
        title: "For mor",
        director: "Wikke & Rasmussen",
        year: "2001",
        duration: "3 Sange+underscore",
        img: FarMor
    },
    {
        title: "Der var engang en dreng",
        year: "2006",
        director: "Wikke & Rasmussen",
        duration: "3 Sange+underscore",
        img: JegVilHa
    },
    {
        title: "Julestjerner",
        year: "2012",
        director: "Wikke & Rasmussen",
        duration: "7 Sange+underscore",
        img: JuileStjerner
    },
    {
        title: "GooseBoy ",
        year: "2019",
        director: "Wikke & Rasmussen",
        duration: "3 Sange+underscore",
        img: GooseImg
    },
];

const AnimatedCard = styled(Card)(({ theme }) => ({
  transform: 'translateY(100px)',
  opacity: 0,
  transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
  '&.visible': {
    transform: 'translateY(0)',
    opacity: 1,
  },
}));

function FilmMusik() {
    const cardRefs = useRef([]);
    const [visibleCards, setVisibleCards] = useState([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setVisibleCards((prev) => [...prev, entry.target]);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.5 }
        );

        cardRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            backgroundColor="#0e151e"
        >
            <Typography variant="h3" sx={{ color: 'white', marginY: '2rem' }}>
                Film musik
            </Typography>
            <Grid container spacing={2}>
                {listoOfFilms.map((film, index) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        key={film.title}
                        ref={(el) => (cardRefs.current[index] = el)}
                    >
                        <AnimatedCard
                            className={visibleCards.includes(cardRefs.current[index]) ? 'visible' : ''}
                            sx={{
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                marginTop: '2rem',
                                display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        md: 'row'
                                    },
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={film.img}
                                alt={film.title}
                                sx={{
                                    width: { xs: '8rem', md: '10rem' },
                                    borderRadius: 2,
                                    flexShrink: 0
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    padding: 2
                                }}
                            >
                                <Typography variant="h6" sx={{ color: 'white' }}>
                                    {film.title}
                                </Typography>
                                <Typography variant="h6" sx={{ color: 'white' }}>
                                    {film.year}
                                </Typography>
                                <Typography variant="h6" sx={{ color: 'white' }}>
                                    {film.director}
                                </Typography>
                                <Typography variant="h6" sx={{ color: 'white' }}>
                                    {film.duration}
                                </Typography>
                            </Box>
                        </AnimatedCard>
                    </Grid>
                ))}
            </Grid>
        <Button sx={{ my: 5 }} variant="contained" color="primary">
            Se alle film
        </Button>

        </Box>
    );
}

export default FilmMusik;

